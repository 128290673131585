.loadingWrapper {
  position: absolute;
  width: 100vw;
  background-color: white;
  z-index: 1;
  opacity: 0.7;
  top: 170px;
  left: 0;
  bottom: 0;
  padding-top: 65px;
}

.trackingNumbersDataCell {
  max-width: 250px;
}

.orderLineStatusCaption {
  white-space: nowrap;
}

.numberColumn .receiveButton {
  display: flex;
  text-transform: lowercase;
  padding: 0;
}
