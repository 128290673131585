input.InputQty {
  font-size: 1rem;
  width: 70px;
  /*text-align: center;*/
  /*padding: 3px;*/
}
.PageGreyRow {
  width: 100%;
  background: #ccc;
  padding: 10px;
  margin: 3px 0px;
}
