.formControl {
  color: white;
}

.formControl .MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.formControl  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}

.formControl .MuiSelect-icon {
  color: rgba(255, 255, 255, 0.54);
}

.formControl .MuiSelect-select {
  color: white;
}
